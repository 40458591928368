@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css');
@import url('https://fonts.cdnfonts.com/css/montserrat');

body {
  margin: 0;
  font-family: 'Pretendard Variable', Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI',
    'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  @apply text-black;
}

button {
  @apply bg-transparent border-none outline-none p-0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  @apply box-border select-none overscroll-none;

  --grayscale-black: #202020;
  --grayscale-800: #393636;
  --grayscale-500: #595757;
  --grayscale-400: #898989;
  --grayscale-300: #b5b5b7;
  --grayscale-200: #e4e2e2;
  --grayscale-100: #f2f2f5;
  --grayscale-white: #ffffff;

  --brand-red-800: #a70d2e;
  --brand-red-500: #e20d3a;
  --brand-red-100: #ffe7ec;

  font-family: 'Pretendard Variable', Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI',
    'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}

p,
div,
h1,
h2,
h3,
h4,
h5,
input,
span {
  color: inherit;
}

input::placeholder {
  font-family: 'Pretendard Variable', Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI',
    'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
p,
span {
  @apply m-0;
}

@layer components {
  .input {
    padding: 17px 12px;
    margin-bottom: 7px;
    @apply block w-full border border-gray100 rounded-px5 placeholder:text-gray300 text-sm font-medium relative disabled:bg-white;
  }
}
